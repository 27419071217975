

























































































































.box {
	margin: 0;
	padding: 0;
	display: flex;
	width: 100%;
	height: 100%;

	.left {
		display: flex;
		width: 70%;
		background: url('https://ylzdedu.n8.bjmantis.cn/static/media/login_background.46c65846.jpg') center center / 100% 100% no-repeat;
	}

	.right {
		flex: 1;
		height: 100vh;
		background-color: #0c2b4a;
		margin-left: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		
		.logo {
			margin-top: 60%;
			margin-bottom: 50px;
			padding: 15px;
			width: 270px;
			height: 68px;
			img {
				width: 100%;
			}
		}

		.logo2 {
			margin-top: 70px auto;
			
			// margin-bottom: 50px;
			padding: 50px;
			text-align: center;
			width: 200px;
			height: 50px;
			img {

				min-width: 200px;
				max-width: 200px;
			}
		}

		.user {
			width: 100%;
		}

		.btn {
			background-color: #28d1a1;
			border: 0;
			color: #fff;
			font-size: 15px;
			letter-spacing: 12px
		}
	}
}
